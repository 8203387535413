import React from 'react';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { Flex } from '../Core/Flex';
import Image, { IImage } from '../Image/Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface IImageCardProps {
  color?: string;
  image: IImage | null;
  text?: string;
  title?: string;
  cta?: {
    to?: string | null;
    href?: string | null;
    anchorId?: string | null;
    label?: string;
    style: 'link' | 'outline' | 'solid' | 'ghost' | 'unstyled' | undefined;
  };
  backTextClassName?: string;
  frontTextClassName?: string;
}

const FrontSide = ({
  frontTextClassName,
  title,
  image,
}: Pick<IImageCardProps, 'frontTextClassName' | 'title' | 'image'>) => {
  return (
    <Box>
      <Flex
        className="absolute z-10 w-full h-full text-center"
        justify="center"
        align="center"
      >
        <Text
          variant={TEXT_STYLES.H3}
          className={tw(
            frontTextClassName ?? '!text-5',
            '!font-black text-white break-words'
          )}
          style={{ textShadow: '1px 1px 2px hsla(345, 6%, 13%, 1.00)' }}
        >
          {title}
        </Text>
      </Flex>
      <Image
        image={image}
        withOverlay
        imageClassName="w-full h-full object-cover"
        className="object-cover w-full h-full"
      />
    </Box>
  );
};

const BackSide = ({
  backTextClassName,
  color,
  text,
  cta,
}: Pick<IImageCardProps, 'color' | 'backTextClassName' | 'text' | 'cta'>) => {
  return (
    <Flex
      className="absolute top-0 left-0 z-10 invisible w-full h-full p-6 text-center group-hover:visible"
      direction="column"
      justify="center"
      align="center"
      style={{ background: color || 'white' }}
    >
      <Text variant={TEXT_STYLES.H5} className={backTextClassName}>
        {text}
      </Text>
      <ThemedButton
        variant={cta?.style}
        className="hidden lg:block mt-5 !transition-none"
        size="sm"
      >
        {cta?.label}
      </ThemedButton>
    </Flex>
  );
};

const ImageCard: FCWithClassName<IImageCardProps> = (props) => {
  const { color, image, text, title, cta } = props;
  return (
    <StyledLink
      to={'/' + cta?.to}
      href={cta?.href ?? undefined}
      anchorId={cta?.anchorId}
      className={tw(
        'w-full relative aspect-w-1 aspect-h-1 cursor-pointer group',
        props.className
      )}
    >
      <FrontSide
        frontTextClassName={props.frontTextClassName}
        title={title}
        image={image}
      />
      <BackSide
        color={color}
        text={text}
        backTextClassName={props.backTextClassName}
        cta={cta}
      />
    </StyledLink>
  );
};

export default ImageCard;
