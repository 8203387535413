import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Grid, Stack } from '../../components/Core';
import ImageCard from '../../components/ImageCard';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import { BUTTON_STYLES } from '../../constants/ui';
import { IWordpressFeaturedImage } from '../../types/wordpress/blocks';
import { serializeImageForImageComponent } from '../../utils/serializeImageForImageComponent';

export interface IServiceDataForCard {
  id?: string;
  featuredImage: IWordpressFeaturedImage;
  title?: string;
  uri?: string;
  informationsService: {
    couleur?: string;
    description?: string;
    shortDescription?: string;
  };
}
type IServicesTemplate = PageProps<Queries.PageServicesQuery, IPageContext>;

const ServicesPageTemplate: FCWithClassName<IServicesTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPage;
  const pageTemplateData = 'contenuServices' in data.wpPage.template ? data.wpPage.template.contenuServices : null;
  const services = data.allWpService.nodes;
  if (!pageData || !pageContext.defaultLanguage || !pageTemplateData) return null;
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <SectionWithTitle
        heading={pageTemplateData.sectionServicesParThematique?.titre}
        headingTag="h1"
        className="relative z-10 mt-24"
      >
        <Stack className="w-full space-y-gap">
          <Grid className="w-full justify-items-start gap-y-12 gap-x-4 xl:gap-x-12 grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
            {services.map((service) =>
              service ? (
                <ImageCard
                  key={service.id}
                  image={serializeImageForImageComponent({
                    image: service.featuredImage?.node, // TODO : use the square image when available
                  })}
                  title={service.title}
                  text={service.informationsService.description}
                  color={service.informationsService.couleur}
                  cta={{
                    label:
                      pageTemplateData.sectionServicesParThematique
                        ?.buttonLabel,
                    style: BUTTON_STYLES.SOLID,
                    to: service.uri,
                  }}
                  backTextClassName="!text-xl"
                />
              ) : null
            )}
          </Grid>
        </Stack>
      </SectionWithTitle>
    </Layout>
  );
};

export default ServicesPageTemplate;

export const query = graphql`
  query PageServices($id: String, $currentLanguageSlug: String) {
    wpPage(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      template {
        ... on WpTemplate_PageServices {
          contenuServices {
            sectionServicesParThematique {
              buttonLabel
              description
              filterButtonLabel
              titre
            }
          }
        }
      }
    }
    allWpService(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        featuredImage {
          node {
            ...MediaItemFragment
            localFile {
              childImageSharp {
                gatsbyImageData(height: 340, width: 340, layout: CONSTRAINED)
              }
              publicURL
            }
          }
        }
        title
        uri
        informationsService {
          couleur
          description
        }
      }
    }
  }
`;
